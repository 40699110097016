import PropTypes from "prop-types"
import React,{useState} from "react"
import Image from "../imagesComponents/image";
import {Nav,NavDropdown, Navbar,Row, Col,Container,Dropdown} from "react-bootstrap"
// import locationIcon from '../../images/icons/location.png';
// import SocialNetworksBox from '../SocialNetworksBox';
import headerStyles from './header.module.css'


const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const show = showMenu ? { 
    position:'absolute',
    top:50,
    marginRight:'-100px',
    display:'block',
    backgroundColor:'#E9BF3C',
    zIndex:10
  }:{
    position:'absolute',
    top:50,
    marginRight:'-100px',
    display:'none',
    backgroundColor:'#E9BF3C',
    zIndex:10
  }


  return(
  <>
    {/* <Container className={headerStyles.topHeaderContact} >
      <Row className="justify-content-md-center">
        <Col xs={12} md={5} style={{paddingTop:'5px'}}>
          <text style={{fontSize:'10px',color:'#566573',paddingLeft:'10px'}} >OWNER, GRI &nbsp;&nbsp;&nbsp;&nbsp;541-915-5310&nbsp;&nbsp;&nbsp;&nbsp;SuzkSparks@gmail.com</text>
        </Col>
        <Col xs={12} md={5} className={headerStyles.alignRight}>
          <SocialNetworksBox size={'20px'} margin={'0px 0px 0 0'} containerMargin={'10px 0 0 0'} />
        </Col>
      </Row>
    </Container>
  <Container style={{backgroundColor:'#F8F9F9'}}>
    <Row className="justify-content-md-center">
      <Col xs={12} md={3}>
        <div className={headerStyles.topHeader1}><Image /></div>
      </Col>
      <Col xs={12} md={4}>
        <div className={headerStyles.topHeaderContactSmall}><text style={{fontSize:'10px',color:'#566573'}} >OWNER, GRI &nbsp;&nbsp;&nbsp;&nbsp;541-915-5310&nbsp;&nbsp;&nbsp;&nbsp;SuzkSparks@gmail.com</text></div>
      </Col>
      <Col xs={12} md={3}>
          <div className={headerStyles.topHeader3}>
            <ul style={{display:'inline-flex'}}>
            <li className={headerStyles.topLicenses}>
              <h5 className={headerStyles.labelTitle}>Based in Eugene, <br/>Serving Lane County</h5>
              <p className={headerStyles.labelSubtitle}>
                CCB License #209662
              </p>
            </li>
            </ul>
          </div>
      </Col>
    </Row>
  </Container> */}

  <Container fluid={false} style={{backgroundColor:'#E9BF3C'}}>
    <Row className="justify-content-md-center">
    <Col xs={12} sm={8} md={8} >
    <Navbar collapseOnSelect expand="sm" variant="light">
      <Navbar.Brand href="/">
        {/* <div className={headerStyles.navbarLogo}><Image /></div> */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto" >
          <Nav.Link href="/" className={headerStyles.menuItemLeft} >Home</Nav.Link>
          <NavDropdown title="Services" id="collasible-nav-dropdown"  className={headerStyles.menuItemLeft} >
                  <NavDropdown.Item  href="/assessment" >Assessment</NavDropdown.Item>
                  <NavDropdown.Item href="/organization">Organization</NavDropdown.Item>
                  <NavDropdown.Item href="/stateclearing">Estate Clearing</NavDropdown.Item>
                  <NavDropdown.Item href="/tradesmen">Tradesmen Services</NavDropdown.Item>
                  <NavDropdown.Item href="/staging">Staging</NavDropdown.Item>
                  <NavDropdown.Item href="/listing">Listing</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/about" className={headerStyles.menuItemLeft}>About</Nav.Link>
          <Nav.Link href="/contact" className={headerStyles.menuItemLeftLast} >Contact us</Nav.Link>
          {/* <NavDropdown title="Contact" id="collasible-nav-dropdown"  className={headerStyles.menuItemLeft}>
                  <NavDropdown.Item  href="/mystory" >How it Developed</NavDropdown.Item>
                  <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="/contact" className={headerStyles.menuItemLeftLastSocials} >
            <SocialNetworksBox size={'30px'}  containerMargin={'10px 0 0 0'} margin={'1px 10px 0 0'}/>
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </Col>
  </Row>
  </Container>
  </>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

// const menuItemStyle = {
//   padding:'12px 15%',
//   textAlign:'center',
//   fontFamily:''
// }
// const menuItemDropStyle = {
//   padding:'4px 15%',
//   textAlign:'center',
//   top: "55px",
//   borderRadius: "1px"
// }