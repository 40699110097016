
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Image from '../imagesComponents/image';
import CustomImage from '../imagesComponents/CustomImage';
import footerStyles from './footer.module.css';

const Footer = () => {
    return(
      <Container fluid={false} className={footerStyles.root}>
        <Row>
          <Col sm={12} md={1} />
          <Col sm={12} md={3} >
            <div className={footerStyles.footerSection1}>
              <div className={footerStyles.footerLogoBox}>
                <Image/>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4} className={footerStyles.sectionTwo}>
            <div className={footerStyles.sectionTwoChild}>
              <h5 className={footerStyles.title}>Contact Us</h5>
              <li className={footerStyles.topLicenses}>
                <h5 className={footerStyles.labelSubtitle}>Based in Eugene, <br/>Serving Lane County</h5>
              </li>
            </div>
          </Col>
          <Col sm={12} md={3} className={footerStyles.sectionThree}>
            <div className={footerStyles.sectionThreeChild}>
                <p className={footerStyles.labelSubtitle} style={{fontFamily:'metropolisbold'}}>541 915-5310</p>
                <p className={footerStyles.labelSubtitle} style={{marginTop:'-15px'}}>Broker, GRI, SRES.</p>
                <div className={footerStyles.licenses}>
                  <div className={footerStyles.rowLicenses}>
                  <CustomImage src={"iconSres.png"} style={{width:'40px',height:'20px',float:'left',marginRight:'5px'}}/>
                  <CustomImage src={"iconGri.png"} style={{width:'40px',height:'20px',float:'left',marginRight:'5px'}}/>
                  </div>
                </div>
                <p className={footerStyles.labelSubtitle,{marginTop:"-10px"}}>
                  <a className={footerStyles.link} href="mailto:susan@homeupstaging.com">susan@homeupstaging.com</a>
                </p>
                <p className={footerStyles.pContactSmallLicenses}>
                NRDS 704501009<br/>
                OREL 950400244<br/>
                Upstaging CCB 209662
              </p>
            
            </div>
          </Col>
          <Col sm={12} md={1} />
        </Row>
        <Row className={footerStyles.copyright} >
          <Col sm={12} className={footerStyles.copyrighttext}>
          {new Date().getFullYear()} © UpStaging | All rights reserved by Corbin &amp; Brooks
            {` `} 
          </Col>
        </Row>
      </Container>
    )
}


export default Footer;
