import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const CustomImage = ({ style, alt, src, className,width,height }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(img => img.node.relativePath.includes(src));
  if (!image) return null;

  // return <div style={{maxWidth:width,maxHeight:height}}>
  //           <Img fluid={image.node.childImageSharp.fluid} alt={alt} style={style} className={className} />;
  //         </div>
  return <Img fluid={image.node.childImageSharp.fluid} alt={alt} style={style} className={className} />;
};

export default CustomImage;